import React from 'react';
import styles from './termsStyle.module.css';

const CopyRight = () => {

  return (
    <div className={styles.contentWrapper}>
        <h2 className={styles.title}>Copyright Notice</h2>
        <p>All content on this website, including but not limited to text, graphics, logos, icons, images, audio clips, and software, is the property of Resum-AI, operated by Joshua Reid, or its content suppliers and is protected by United States and international copyright laws. The compilation (meaning the collection, arrangement, and assembly) of all content on this site is the exclusive property of Resum-AI and is protected by United States and international copyright laws.</p>
        <p>The content and software on this site may be used as a personal resource. Any other use, including the reproduction, modification, distribution, transmission, republication, display, or performance, of the content on this site is strictly prohibited without the express written consent of Resum-AI.</p>
        <p>Resum-AI respects the intellectual property rights of others and expects its users to do the same. If you believe that your copyrighted work has been copied in a way that constitutes copyright infringement, please provide us with the following information:
            <ol>
                <li>A physical or electronic signature of the copyright owner or a person authorized to act on their behalf;</li>
                <li>Identification of the copyrighted work claimed to have been infringed;</li>
                <li>Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material;</li>
                <li>Your contact information, including your address, telephone number, and an email address;</li>
                <li>A statement by you that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and</li>
                <li>A statement that the information in the notification is accurate, and, under penalty of perjury, that you are authorized to act on behalf of the copyright owner.</li>
            </ol>
            Please send this information to help@resum-ai.com.</p>
  </div>
  );
};

export default CopyRight;