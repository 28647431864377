import React from 'react';
import styles from './termsStyle.module.css';

const Disclaimer = () => {

  return (
    <div className={styles.contentWrapper}>
        <h2 className={styles.title}>Disclaimer</h2>
        <p>Last updated: 4/28/2023</p>
        
        <h3 className={styles.header}>No Guarantee of Success</h3>
        <p>Resum-AI, operated by Joshua Reid, provides a service for generating resumes and cover letters using OpenAI's language models. While our service aims to help users create targeted and efficient resumes and cover letters, we do not guarantee success in obtaining a job, receiving a callback, or securing an interview. The use of our service is at the user's own risk, and we disclaim any implied or express warranties for the effectiveness of the generated content.</p>

        <h3 className={styles.header}>Internet Connection and Loss of Tokens</h3>
        <p>Users are advised to ensure they have a stable internet connection when using our service to generate resumes and cover letters. If a user loses their token due to an unstable internet connection, refreshing the page during the generation process, or any other user error, they are not entitled to a refund.</p>
        
        <h3 className={styles.header}>Third-Party Services</h3>
        <p>Our website uses Stripe for processing transactions and sends text entered in the "Experience" and "Job Description" fields to OpenAI for resume generation. We are not responsible for the privacy practices, security measures, or content of these third-party services. Users should review the terms and conditions and privacy policies of these third parties to understand their practices and how they handle user data.</p>

        <h3 className={styles.header}>Accuracy, Completeness, and Timeliness of Information</h3>
        <p>The content generated by our service is created using OpenAI's language models and may not always be accurate, complete, or up-to-date. We disclaim any liability for any errors, inaccuracies, or omissions in the content provided on our website.</p>

        <h3 className={styles.header}>No Professional Advice</h3>
        <p>Our service is not intended to provide professional career or job search advice. Any information or suggestions provided on our website should be used at the user's discretion and should not be considered as professional advice.</p>
  
        <h3 className={styles.header}>Changes to This Disclaimer</h3>
        <p>We may update this Disclaimer from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage you to periodically review this page for the latest information on our disclaimer practices.</p>

        <h3 className={styles.header}>Contact Us</h3>
        <p>If you have any questions about this Disclaimer, please contact us at help@resum-ai.com.</p>
  </div>
  );
};

export default Disclaimer;