import React from 'react';

function PaymentConfirmation({ amountSpent }) {
  return (
    <div style={styles.wrapper}>
      <h1>Payment Confirmation</h1>
      <p>You have successfully bought Tokens</p>
      <p>Thank you for your purchase!</p>
    </div>
  );
}

export default PaymentConfirmation;

const styles = {
  wrapper: {"width": "50%", "margin": "auto", "textAlign": "center"}
}