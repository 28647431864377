import React from 'react';
import { Link } from 'react-router-dom';
import styles from './css/footer.module.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const isMobile = window.innerWidth <= 1200;

  return (
    <footer className={styles.footer}>
      <div className={styles.links}>
        <Link to="/terms_policies" className={styles.link}>Terms and Policies</Link>
      </div>
      {!isMobile && (<p>Contact Us: <a className={styles.link} href="mailto:help@resum-ai.com">help@resum-ai.com</a></p>)}
      <p className={styles.copyRight}>© {currentYear} Resum-AI</p>
    </footer>
  );
};

export default Footer;
