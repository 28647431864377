import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import GenerateResume from './pages/generateResume';
import LoginForm from './authentication/LoginForm';
import SignupForm from './authentication/SignUp';
import PaymentForm from './pages/payment_form';
import PaymentConfirmation from './pages/paymentConfirmation';
import DisplayResumes from './pages/displayResumes';
import DisplayDescriptions from './pages/DisplayDescriptions';
import Landing from './pages/landing';
import { useAuth } from './authentication/useAuth';
import PersonalInfo from './pages/personalInfo';
import mainContentStyles from "./router.module.css"
import TermsPolicies from './pages/terms/terms_policies';
import Privacy from './pages/terms/privacy';
import Terms from './pages/terms/terms';
import AUP from './pages/terms/aup'
import Cookie from './pages/terms/cookie'
import CopyRight from './pages/terms/copyright'
import Disclaimer from './pages/terms/disclaimer'
import Refund from './pages/terms/refund'
import PaymentSettings from "./pages/paymentSettings"
import VerifyEmail from './authentication/VerifyEmail';
import { firebaseApp } from "./firebase/firebaseConfig";
import {getAuth} from "firebase/auth";
import UnsubscribeFromEmail from './pages/unsubscribeFromEmail';


//blog imports
import BlogList from './blog/blog_list';
import UnlockCareerPotentialAiProfessionalGrowth from './blog/unlock-career-potential-ai-professional-growth';
import Blog from './blog/blog';


function Router() {
  const auth = getAuth(firebaseApp);
  const [user] = useAuth();
  const [tokenUpdated, setTokenUpdated] = useState(false);
  const [isVerified, setIsVerified] = useState(false)
  const [hasAccess, setHasAccess] = useState(false)

  useEffect(() => {
    if(user){
      auth.currentUser.reload().then(() => {
        if (auth.currentUser.emailVerified) {
          setIsVerified(true)
        } else {
          setIsVerified(false)
        }
      });
    }
  }, [user])

  useEffect(() => {
    if(user && isVerified){
      setHasAccess(true)
    }
  }, [user, isVerified])

  const handleTokenUpdated = () => {
    setTokenUpdated(!tokenUpdated);
  };

    return (
      <BrowserRouter>
        <div className={mainContentStyles.mainContent}>
        <Header tokenUpdated={tokenUpdated} />
        <div className={mainContentStyles.content}>
        <Routes>
            {hasAccess && (
              <>
              <Route path="/" element={<GenerateResume onTokenUpdate={handleTokenUpdated}/>} />
              <Route path="/payment" element={<PaymentForm />} />
              <Route path="/paymentConfirmation" element={<PaymentConfirmation />} />
              <Route path="/view_resumes" element={<DisplayResumes />} />
              <Route path="/view_experience" element={<DisplayDescriptions />} />
              <Route path="/personal_info" element={<PersonalInfo />} />
              <Route path="/payment_settings" element={<PaymentSettings />} />
              </>
            )}
            {(user && !isVerified) && (
              <>
              <Route path="/*" element={<VerifyEmail />} />
              <Route path="/" element={<VerifyEmail />} />
              <Route path="/signup" element={<SignupForm />} />
              </>
            )}
            {!hasAccess && (
              <>
              <Route path="/" element={<Landing />} />
              <Route path="/login" element={<LoginForm />} />
              <Route path="/signup" element={<SignupForm />} />
              </>
            )}
              <Route path="/terms_policies" element={<TermsPolicies />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/aup" element={<AUP />} />
              <Route path="/cookie" element={<Cookie />} />
              <Route path="/copyright" element={<CopyRight />} />
              <Route path="/disclaimer" element={<Disclaimer />} />
              <Route path="/refund" element={<Refund />} />
              <Route path="/unsubscribe_from_email" element={<UnsubscribeFromEmail />} />

              {/* Blog routes */}

              <Route path="/blog_list" element={<BlogList />} />
              <Route path="/unlock-career-potential-ai-professional-growth" element={<UnlockCareerPotentialAiProfessionalGrowth />} />
              <Route path="/blog/:title" element={<Blog />} />

        </Routes>
        </div>
        <Footer/>
        </div>
      </BrowserRouter>
    );
  }
  
  export default Router;