import React from 'react';
import { Link } from 'react-router-dom';
import styles from './css/popup.module.css';

const Popup = ({ isOpen, handleClose, message, sub_message, displayButton, buttonText, linkPath, onButtonClick }) => {
  if (!isOpen) {
    return null;
  }

  const handleClick = () => {
    handleClose();
    onButtonClick();
  }

  return (
    <div className={styles.overlay}>
      <div className={styles.popup}>
        <button className={styles.closeButton} onClick={handleClose}>
          X
        </button>
        <h2>{message}</h2>
        <p>{sub_message}</p>
        {displayButton && (
          <Link className={styles.link} to={linkPath}>
            <button className={styles.button} onClick={handleClick}>
              {buttonText}
            </button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default Popup;