import React from 'react';
import styles from './termsStyle.module.css';

const Terms = () => {

  return (
    <div className={styles.contentWrapper}>
        <h2 className={styles.title}>Terms of Service</h2>
        <p>Last updated: 4/28/2023</p>
        <p>Please read these Terms of Service ("Terms", "Terms of Service") carefully before using the Resum-AI website (the "Service") operated by Joshua Reid ("us", "we", or "our").</p>
        <p>Your access to and use of the Service is conditioned upon your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who wish to access or use the Service.</p>
        <p>By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms, then you do not have permission to access the Service.</p>

        <h3 className={styles.header}>Accounts</h3>
        <p>When you create an account with us, you guarantee that the information you provide is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on the Service.</p>
        <p>You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>

        <h3 className={styles.header}>Tokens and Services</h3>
        <p>Users can purchase Tokens through the Stripe API, which can be used to generate resumes and cover letters using the OpenAI API. One Token can be used to generate one resume, one cover letter, or one of each.</p>
        <p>Resumes generated using the Service can be downloaded and used for job applications. Cover letters generated by the Service cannot be downloaded but can be copied and pasted. Users are responsible for any modifications made to the resumes or cover letters after generating them using the Service.</p>

        <h3 className={styles.header}>Intellectual Property</h3>
        <p>Users retain the intellectual property rights to the content they input into their resumes and cover letters. If the Service becomes unavailable and users are unable to access their resumes and cover letters, they have the right to demand access to the documents they have already downloaded.</p>

        <h3 className={styles.header}>Prohibited Content</h3>
        <p>Users are prohibited from inputting any illegal content, including but not limited to death threats, into their resumes and cover letters. We reserve the right to remove any such content and terminate the user's account without prior notice.</p>

        <h3 className={styles.header}>No Warranty or Guarantee</h3>
        <p>We do not provide any warranties or guarantees regarding the effectiveness of the resumes and cover letters generated on our website. The Service is a tool to help users create targeted resumes and cover letters for specific jobs, but we cannot promise that users will receive callbacks, interviews, or job offers.</p>

        <h3 className={styles.header}>Complaints and Disputes</h3>
        <p>Users can email us with any complaints or disputes related to the services provided by our website. We will do our best to address and resolve any issues that arise.</p>

        <h3 className={styles.header}>Data Retention</h3>
        <p>User accounts and associated data, including resumes and cover letters, will be deleted after two years of inactivity. Users cannot request the deletion of their accounts and associated data.</p>

        <h3 className={styles.header}>Changes</h3>
        <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
        <p>By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Service, and you must discontinue your access and use of the Service immediately.</p>
  </div>
  );
};

export default Terms;