import React, { useState, useEffect } from 'react';
import styles from './css/blog.module.css';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { firebaseApp } from '../firebase/firebaseConfig';
import robot_typing from "./images/robot_typing.jpeg";
import { useParams } from 'react-router-dom';

const firestore = getFirestore(firebaseApp);

const Blog = () => {
    const [title, setTitle] = useState("");
    const [intro, setIntro] = useState("");
    const [content, setContent] = useState([]);
    const [conclusion, setConclusion] = useState("");

    // Extract the title parameter from the URL using useParams
    const { title: urlTitle } = useParams();

    useEffect(() => {
        const fetchBlogContent = async () => {
            // Use the title extracted from the URL to query Firestore
            // Ensure it's treated as a string for security
            const sanitizedTitle = String(urlTitle);
            const blogRef = doc(firestore, "blogs", sanitizedTitle);

            const blogSnap = await getDoc(blogRef);
            if (blogSnap.exists()) {
                let blog_object = JSON.parse(blogSnap.data()['blog-content']);
                setTitle(blog_object.title);
                setIntro(blog_object.introduction);
                setConclusion(blog_object.conclusion);

                let content_array = [];
                blog_object.content.forEach((item) => {
                    let hold = (
                        <div>
                            <h2 className={styles.header}>{item.paragraph_title}</h2>
                            <p dangerouslySetInnerHTML={{ __html: item.paragraph_content }}></p>
                        </div>
                    );
                    content_array.push(hold);
                });
                setContent(content_array);
            } else {
                console.log("No such document!");
            }
        };

        fetchBlogContent();
    }, [urlTitle]); // Add urlTitle to dependency array so that the effect runs when the title changes

    return (
      <div className={styles.contentWrapper}>
        <h1 className={styles.title} >{title}</h1>
        <img className={styles.articleImage} src={robot_typing} alt="AI Career Growth" />
        <h2 className={styles.header}>Introduction:</h2>
        <p dangerouslySetInnerHTML={{ __html: intro }}></p>
        {content}
        <h2 className={styles.header}>Conclusion:</h2>
        <p dangerouslySetInnerHTML={{ __html: conclusion }}></p>
      </div>
    );
};

export default Blog;
