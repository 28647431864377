import React, { useState, useRef, useEffect } from "react";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseApp } from "../firebase/firebaseConfig";
import styles from "./css/SignupForm.module.css";
import { useNavigate } from "react-router-dom";

const auth = getAuth(firebaseApp);
const functions = getFunctions();

const SignupForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [checkbox, setCheckBox] = useState("true");
  const [error, setError] = useState("");
  const [awaitingVerification, setAwaitingVerification] = useState(false);

  const navigate = useNavigate();
  const formRef = useRef();

  const handleSubmit = (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // User created successfully
        const user = userCredential.user;
        const userId = user.uid;

        sendEmailVerification(user)
          .then(() => {
            // Email sent
            console.log("Sending verification email");
            setAwaitingVerification(true)
          })
          .catch((error) => {
            // Handle Errors here.
            const errorMessage = error.message;
            console.error("Verification email error:", errorMessage);
            setError(errorMessage);
          });

        const addToken = async () => {
          try {
            const addTokenOnSignUp = httpsCallable(
              functions,
              "addTokenOnSignUp"
            );
            const result = await addTokenOnSignUp({ userId: userId });
            return result.data; // Return the data from the callable function
          } catch (error) {
            console.error("Error:", error);
            throw error; // Rethrow the error so it can be handled by the caller
          }
        };
        const tokenAdded = await addToken();

        const addUserDetails = async () => {
          try {
            const addUserDetailsOnSignUp = httpsCallable(
              functions,
              "addUserDetailsOnSignUp"
            );
            const result = await addUserDetailsOnSignUp({
              userId: userId,
              email: email,
              promo_email: checkbox
            });
            return result.data; // Return the data from the callable function
          } catch (error) {
            console.error("Error:", error);
            throw error; // Rethrow the error so it can be handled by the caller
          }
        };
        const userDetailsAdded = await addUserDetails();
      })
      .catch((error) => {
        // Error occurred while creating user
        const errorMessage = error.message;
        console.error(errorMessage);
        if (errorMessage.includes("auth/email-already-in-use"))
          setError("A user with that email already exist");
        else setError(errorMessage);
      });
  };

  const handleVerificationCheck = () => {
    auth.currentUser.reload().then(() => {
      if (auth.currentUser.emailVerified) {
        // Email has been verified
        navigate("/", { state: { fromSignup: true } });
      } else {
        // Email not verified yet
        setError("Please verify your email first.");
      }
    });
  };

  return (
    <div className={styles.container}>
      {awaitingVerification && (
        <div>
          <p className={styles.info}>
            Please verify your email to log into your account.
          </p>
          <button className={styles.button} onClick={handleVerificationCheck}>
            I have verified my email, check again
          </button>
        </div>
      )}
      {!awaitingVerification && (
        <div>
          <form ref={formRef} className={styles.form}>
            <h2 className={styles.title}>Signup</h2>
            {error && <p className={styles.error}>{error}</p>}
            <label className={styles.label}>
              Email:
              <input
                className={styles.input}
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </label>
            <label className={styles.label}>
              Password:
              <input
                className={styles.input}
                type="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </label>
            <label className={styles.label}>
              Confirm Password:
              <input
                className={styles.input}
                type="password"
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
              />
            </label>
            <label className={styles.checkbox}>
              <input
                className={styles.input}
                type="checkbox"
                checked={checkbox}
                onChange={() => setCheckBox(!checkbox)}
              />
              Send me promotional and informational emails
            </label>
            <button className={styles.button} onClick={handleSubmit}>
              Sign Up
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default SignupForm;
