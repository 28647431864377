import React, { useState, useEffect } from "react";
import { useAuth } from "../authentication/useAuth";
import { getFirestore, getDoc, doc } from "firebase/firestore";
import { firebaseApp } from "../firebase/firebaseConfig";
import "./css/coverletter.css"

const CoverLetter = (props) => {
    const [user] = useAuth();
    const [name, setName] = useState('Your Name');
    const [hasPurchased, setHasPurchased] = useState(false);

    useEffect(() => {
        const fetchUserName = async () => {
          if (user) {
            const firestore = getFirestore(firebaseApp);
            const nameRef = doc(firestore, "users", user.uid);
            const nameDoc = await getDoc(nameRef);
    
            if (nameDoc.exists()) {
              setName(nameDoc.data().name);
              setHasPurchased(nameDoc.data().hasPurchased);
            }
          }

        };
    
        fetchUserName();
      }, [user]);
    
      const formatCoverLetter = (coverLetterJsonStr) => {
        // Replace placeholders
        coverLetterJsonStr = coverLetterJsonStr.replace("[Your Name]", name).replace("[Your Full Name]", name);
    
        let coverLetterJson;
        try {
            coverLetterJson = JSON.parse(coverLetterJsonStr);
            
            let formatedCoverLetter = coverLetterJson.coverletter.map((part, index) => (
                <p key={index}>{part}</p>
            ));
            
            return formatedCoverLetter;
            
        } catch (e) {
            // If it's not valid JSON, return the original string
            console.log("not json");
            return coverLetterJsonStr;
        }
    }
    

    return(
      <div className={hasPurchased ? "coverletterWrapper" : "coverletterWrapper blurred preventCopy"}>
            {formatCoverLetter(props.data)}
        </div>
    )
}

export default CoverLetter