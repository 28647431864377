import React from 'react';
import styles from './termsStyle.module.css';

const AUP = () => {

  return (
    <div className={styles.contentWrapper}>
        <h2 className={styles.title}>Acceptable Use Policy</h2>
        <p>Last updated: 4/28/2023</p>
        <p>This Acceptable Use Policy outlines the rules and restrictions that govern the use of Resum-AI, operated by Joshua Reid. By accessing or using our website and its services, you agree to abide by this policy.</p>
        
        <h3 className={styles.header}>1. Prohibited Actions</h3>
        <p>Users are prohibited from engaging in the following actions when using Resum-AI:</p>
        <p>a. Creating an unreasonable number of "Descriptions" or "Experience" items in a set period of time, as determined by us. This includes using automated means, such as bots, to submit content to our website.</p>
        <p>b. Attempting to hack, compromise, or otherwise interfere with the security or functionality of the website.</p>
        <p>c. Manipulating or attempting to manipulate the browser or our services to generate a resume or cover letter without using a token.</p>
        <p>d. Spamming our support email or any other communication channels associated with Resum-AI.</p>

        <h3 className={styles.header}>2. Account Sharing</h3>
        <p>Users are not allowed to share their accounts or tokens with others. Each user is responsible for maintaining the confidentiality of their account information and for all activities that occur under their account.</p>
        
        <h3 className={styles.header}>3. User-Generated Content</h3>
        <p>Users are solely responsible for any content they submit or share on Resum-AI, including but not limited to job descriptions, experience items, and any other information that affects the content generated and displayed on resumes. By submitting content, users represent and warrant that they have the necessary rights, licenses, and permissions to provide such content.</p>

        <h3 className={styles.header}>4. Violations of the Acceptable Use Policy</h3>
        <p>If we determine, at our sole discretion, that a user has violated any of the rules outlined in this Acceptable Use Policy, we reserve the right to take appropriate action, which may include issuing a warning, suspending or terminating the user's account, or deleting their tokens, without prior notice or explanation.</p>

        <h3 className={styles.header}>5. Changes to This Acceptable Use Policy</h3>
        <p>We may update this Acceptable Use Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage you to periodically review this page for the latest information on our acceptable use practices.</p>

        <h3 className={styles.header}>Contact Us</h3>
        <p>If you have any questions about this Acceptable Use Policy, please contact us at help@resum-ai.com.</p>
  </div>
  );
};

export default AUP;