import { useState, useEffect } from "react";
import ask_question from "../components/ask_question";
import Resume from "../components/resume_sub_components/resume";
import CoverLetter from "../components/coverLetter";
import {
  basic_resume_pre_prompt,
  customized_resume_pre_prompt,
  customized_resume_post_prompt,
  cover_letter_pre_prompt,
  cover_letter_post_prompt,
} from "../assets/prompts";
import "./css/GenerateResume.css";
import {
  getFirestore,
  collection,
  onSnapshot,
  doc,
  updateDoc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { firebaseApp } from "../firebase/firebaseConfig";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation } from "react-router-dom";
import Popup from "../components/popup";
import Help from "../components/help";
import Loading from "../components/Loading";
import { Tab, Tabs } from "@mui/material";
import questionMark from "../assets/svg/question_mark.svg"
import { Link } from 'react-router-dom';

const auth = getAuth(firebaseApp);
const firestore = getFirestore(firebaseApp);

function GenerateResume({ onTokenUpdate }) {
  const [useSavedExperience, setUseSavedExperience] = useState(false);
  const [generateCoverLetter, setGenerateCoverLetter] = useState(false);
  const [generateCoverLetterOnly, setGenerateCoverLetterOnly] = useState(false);
  const [showResume, setShowResume] = useState(false);
  const [experienceText, setExperienceText] = useState("");
  const [resume, setResume] = useState("");
  const [coverLetter, setCoverLetter] = useState("");
  const [customizeResume, setCustomizeResume] = useState(false);
  const [coverLetterText, setCoverLetterText] = useState("");
  const [savedExperiences, setSavedExperiences] = useState([]);
  const [selectedExperience, setSelectedExperience] = useState(null);
  const [resumeName, setResumeName] = useState(null);
  const [coverLetterName, setCoverLetterName] = useState(null);
  const [descriptionName, setDescriptionName] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState(false);
  const [popupSubMessage, setPopupSubMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [expCharCount, setExpCharCount] = useState(0);
  const [coverCharCount, setCoverCharCount] = useState(0);
  const [confirmPopupOpen, setConfirmPopupOpen] = useState(false);
  const [error, setError] = useState({});
  const [showHelp, setShowHelp] = useState(false);
  const [hasPersonalInfo, setHasPersonalInfo] = useState(true);
  const [buttonText, setButtonText] = useState('');
  const [linkPath, setLinkPath] = useState('');

  const [user] = useAuthState(auth);
  const userId = user ? user.uid : "";
  const location = useLocation();

  useEffect(() => {
    const descriptionsRef = collection(
      firestore,
      "items",
      userId,
      "descriptions"
    );
    const unsubscribe = onSnapshot(descriptionsRef, (querySnapshot) => {
      const fetchedDescriptions = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data().data,
      }));
      setSavedExperiences(fetchedDescriptions);
      if (fetchedDescriptions.length > 0)
        setSelectedExperience(fetchedDescriptions[0].data);
    });

    return () => {
      unsubscribe();
    };
  }, [userId, firestore]);

  useEffect(() => {
    if (location.state && location.state?.fromSignup) {
      const storedExperience = localStorage.getItem("workExperience");
      const storedCoverLetterText = localStorage.getItem("jobDescription");
      if (storedExperience) {
        setExperienceText(storedExperience);
      }
      if (storedCoverLetterText) {
        setCoverLetterText(storedCoverLetterText);
      }
    }
  }, [location]);

  const isEmptyDocument = (docData) => {
    return Object.values(docData).every(value => value === '');
  };

  useEffect(() => {
    if (!userId) {
      setHasPersonalInfo(false);
      return;
    }

    const fetchPersonalInfo = async () => {
      const userDocRef = doc(firestore, 'users', userId);
      const docSnapshot = await getDoc(userDocRef);

      if (docSnapshot.exists() && !isEmptyDocument(docSnapshot.data())) {
        setHasPersonalInfo(true);
      } else {
        setHasPersonalInfo(false);
      }
    };

    fetchPersonalInfo();

    const unsubscribe = onSnapshot(doc(firestore, 'users', userId), (docSnapshot) => {
      if (docSnapshot.exists() && !isEmptyDocument(docSnapshot.data())) {
        setHasPersonalInfo(true);
      } else {
        setHasPersonalInfo(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [userId]);

  const handleTextareaChange = (event) => {
    setExperienceText(event.target.value);
    setExpCharCount(event.target.value.length);
  };

  const handleCoverTextareaChange = (event) => {
    setCoverLetterText(event.target.value);
    setCoverCharCount(event.target.value.length);
  };

  const extractJSON = (str) => {
    const startIndex = str.indexOf('{');
    const endIndex = str.lastIndexOf('}');
    if (startIndex === -1 || endIndex === -1) {
        throw new Error('Invalid input string. JSON not found.');
    }
    const jsonStr = str.slice(startIndex, endIndex + 1);
    try {
        return jsonStr;
    } catch (error) {
        throw new Error('Failed to parse JSON.');
    }
  }

  const generateDocument = async (
    prePrompt,
    postPrompt = "",
    addCoverLetterText = false
  ) => {
    const query =
      prePrompt +
      experienceText +
      postPrompt +
      (addCoverLetterText ? coverLetterText : "");
    setLoading(true);

    try {
      let result = await ask_question(query);
      result = result
        .replaceAll("\nHere is your JSON:\n\n", "")
        .replace("JSON:", "")
        .replace(/[\u2018\u2019]/g, "'")
        .replace(/[\u201C\u201D]/g, '"');
      result = extractJSON(result);
      setLoading(false);
      return result;
    } catch (error) {
      setLoading(false);
      if (error.message === "Too many requests") {
        setPopupMessage("It seems OpenAI's servers are overloaded at the moment.. please try again later");
        setButtonText("Close")
        setLinkPath("")
        setPopupOpen(true);
      } else {
        console.error("An error occurred:", error);
      }
    }
  };

  const uploadDocument = async (collectionName, documentName, data) => {
    if (!userId || !documentName) return;
    const docRef = doc(
      firestore,
      "items",
      userId,
      collectionName,
      documentName
    );
    await setDoc(docRef, { data }, { merge: true });
  };

  const handleButtonClick = async () => {
    if (
      (!descriptionName && !useSavedExperience)|| 
      (!resumeName && !generateCoverLetterOnly)|| 
      (!coverLetterName && (generateCoverLetter || generateCoverLetterOnly))) {
      setError({
        description: !descriptionName,
        resume: !resumeName,
        coverLetter: !coverLetterName,
      });
      return;
    }
    let resumeResult, coverLetterResult;

    const tokenRef = doc(firestore, "tokens", userId);
    const tokenDoc = await getDoc(tokenRef);

    if (tokenDoc.exists()) {
      const tokenAmount = tokenDoc.data().tokens;
      if (tokenAmount > 0 || tokenAmount == -1) {
        if (generateCoverLetterOnly) {
          coverLetterResult = await generateDocument(
            cover_letter_pre_prompt,
            cover_letter_post_prompt,
            true
          );
          setCoverLetter(coverLetterResult);
          setShowResume(true);
          await uploadDocument(
            "coverletters",
            coverLetterName,
            coverLetterResult
          );
        } else {
          if (customizeResume) {
            resumeResult = await generateDocument(
              customized_resume_pre_prompt,
              customized_resume_post_prompt,
              true
            );
          } else {
            resumeResult = await generateDocument(basic_resume_pre_prompt);
          }

          setResume(resumeResult);
          setShowResume(true);
          await uploadDocument("resumes", resumeName, resumeResult);

          if (generateCoverLetter) {
            coverLetterResult = await generateDocument(
              cover_letter_pre_prompt,
              cover_letter_post_prompt,
              true
            );
            setCoverLetter(coverLetterResult);
            await uploadDocument(
              "coverletters",
              coverLetterName,
              coverLetterResult
            );
          }
        }
        if (!useSavedExperience) {
          await uploadDocument("descriptions", descriptionName, experienceText);
        }
        let newTokenAmount;
        if(tokenAmount != -1){
          newTokenAmount = tokenAmount - 1;
        } else {
          newTokenAmount = -1;
        }
        const docRef = doc(firestore, "tokens", userId);
        await setDoc(docRef, { tokens: newTokenAmount }, { merge: true });
        onTokenUpdate();
      } else {
        setPopupMessage("Uh Oh! Looks like you're all out of tokens! Click below to buy some more!");
        setButtonText("Buy Tokens!")
        setLinkPath('/payment')
        setPopupOpen(true);
      }
    }
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
  };

  const handleHelpClose = () => {
    setShowHelp(false);
  };

  const handleSelectedExperienceChange = (event) => {
    const selectedExp = JSON.parse(event.target.value);
    setSelectedExperience(selectedExp.data);
    setExperienceText(selectedExp.data);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleConfirmClick = () => {

    if(expCharCount < 100 && !useSavedExperience){
      setPopupMessage("You did not write enough for your description. Please write at least 100 characters for your description before generating a resume or cover letter")
      setButtonText('Go Back')
      setLinkPath('')
      setPopupOpen(true);
    } else if(expCharCount < 300 && !useSavedExperience){
      setPopupMessage("We noticed you didn't write much for your description. Are you sure you want to continue?")
      setPopupSubMessage("The more detailed you are about yourself in the description, the more accurate your resume and or cover letter will be.")
      setConfirmPopupOpen(true);
    } else{
      setPopupMessage("Are you sure you want to generate your resume and or cover letter?")
      setPopupSubMessage("")
      setConfirmPopupOpen(true);
    }
  };

  return (
    <div className="wrapper">
      <Popup
        isOpen={popupOpen}
        handleClose={handlePopupClose}
        message={popupMessage}
        displayButton={true}
        buttonText={buttonText}
        linkPath={linkPath}
      />
      <Popup
        isOpen={confirmPopupOpen}
        handleClose={() => setConfirmPopupOpen(false)}
        message={popupMessage}
        sub_message={popupSubMessage}
        displayButton={true}
        buttonText={"Confirm"}
        onButtonClick={handleButtonClick}
      />
      <Help
        isOpen={showHelp}
        handleClose={handleHelpClose}
      />
      {loading && (
        <Loading message="Generating your documents, please do not refresh this page or close the browser or you may lose your token" />
      )}
      <div className="help-wrapper">
        <div className="experience-wrappers">
          <div className="experience-checkbox">
            <input
              type="checkbox"
              checked={useSavedExperience}
              onChange={() => setUseSavedExperience(!useSavedExperience)}
            />
            <label>Use Saved Experience</label>
          </div>
          {useSavedExperience && (
            <select
              className="select-field"
              onChange={handleSelectedExperienceChange}
            >
              <option>Select an experience</option>
              {savedExperiences.map((experience, index) => (
                <option key={index} value={JSON.stringify(experience)}>
                  {experience.id}
                </option>
              ))}
            </select>
          )}
          </div>
          <div className="no-pers-info-wrapper">
            {!hasPersonalInfo && (<span className="no-pers-info">Update <Link className="link" to="/personal_info">Personal Info</Link> to appear on resume</span>)}
            <img
                className="help-icon"
                src={questionMark}
                alt="Help Icon"
                onClick={() => setShowHelp(true)}
              />
          </div>
      </div>
      {!useSavedExperience && (
        <input
          type="text"
          className="input-field"
          onChange={(e) => setDescriptionName(e.target.value)}
          value={descriptionName}
          placeholder="Give a name for this description"
        />
      )}
      {(error.description && !useSavedExperience) && (
        <p className="error">Please enter a description name.</p>
      )}
      {!generateCoverLetterOnly && (
        <input
          type="text"
          className="input-field"
          onChange={(e) => setResumeName(e.target.value)}
          value={resumeName}
          placeholder="Give a name for your resume"
        />
      )}
      {(error.resume && !generateCoverLetterOnly) && <p className="error">Please enter a resume name.</p>}
      <textarea
        className="textarea"
        value={experienceText}
        onChange={handleTextareaChange}
        readOnly={useSavedExperience}
        maxLength={3000}
        placeholder="Write a few paragraphs about yourself. Include your education, your work experience with dates, and the things you've learned and accomplished at each job. The more thorough you are, the better your resume will come out."
      />
      <div className={`char-count${expCharCount === 3000 ? " flash" : ""}`}>
        Characters: {expCharCount} / 3000
      </div>
      {(generateCoverLetter || generateCoverLetterOnly) && (
        <input
          type="text"
          className="input-field"
          onChange={(e) => setCoverLetterName(e.target.value)}
          value={coverLetterName}
          placeholder="Give a name for your cover letter"
        />
      )}
      {(error.coverLetter && (generateCoverLetter || generateCoverLetterOnly)) && (
        <p className="error">Please enter a cover letter name.</p>
      )}
      {(generateCoverLetter || generateCoverLetterOnly || customizeResume) && (
        <textarea
          className="textarea"
          value={coverLetterText}
          onChange={handleCoverTextareaChange}
          maxLength={6500}
          placeholder="Copy and paste the job description you are applying for"
        />
      )}
      {(generateCoverLetterOnly || generateCoverLetter) && (
        <div className={`char-count${coverCharCount === 6500 ? " flash" : ""}`}>
          Characters: {coverCharCount} / 6500
        </div>
      )}
      <div className="options-wrapper">
        {generateCoverLetterOnly == false && (
          <div className="generate-options">
            <input
              type="checkbox"
              checked={generateCoverLetter}
              onChange={() => setGenerateCoverLetter(!generateCoverLetter)}
            />
            <label>Generate Cover Letter</label>
          </div>
        )}
        {generateCoverLetterOnly == false && (
          <>
            <div className="generate-options">
              <input
                type="checkbox"
                checked={customizeResume}
                onChange={() => setCustomizeResume(!customizeResume)}
              />
              <label>Customize resume for specific job</label>
            </div>
          </>
        )}
        <div className="generate-options">
          <input
            type="checkbox"
            checked={generateCoverLetterOnly}
            onChange={() =>
              setGenerateCoverLetterOnly(!generateCoverLetterOnly)
            }
          />
          <label>Generate Cover Letter Only</label>
        </div>
      </div>
      <button className="button" onClick={handleConfirmClick}>
        {generateCoverLetterOnly
          ? "Generate Cover Letter"
          : generateCoverLetter
          ? "Generate Resume and Cover letter"
          : "Generate Resume"}
      </button>
      {showResume && (
        <>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            {!generateCoverLetterOnly && <Tab label="Resume" />}
            {(generateCoverLetter || generateCoverLetterOnly) && (
              <Tab label="Cover Letter" />
            )}
          </Tabs>
          <div className="resume">
            {activeTab === 0 && !generateCoverLetterOnly && (
              <Resume data={resume}></Resume>
            )}
            {activeTab === 0 && generateCoverLetterOnly && (
              <CoverLetter data={coverLetter}></CoverLetter>
            )}
            {activeTab === 1 && <CoverLetter data={coverLetter}></CoverLetter>}
          </div>
        </>
      )}
    </div>
  );
}

export default GenerateResume;
