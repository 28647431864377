import React, { useState, useEffect } from 'react';
import { getFirestore, doc, setDoc, getDoc, onSnapshot } from 'firebase/firestore';
import { firebaseApp } from '../firebase/firebaseConfig';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import styles from './css/PersonalInfo.module.css';

const auth = getAuth(firebaseApp);
const firestore = getFirestore(firebaseApp);

const PersonalInfo = () => {
  const [personalInfo, setPersonalInfo] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    linkdn: '',
    portfolio: '',
    github: '',
    other1: '',
    other2: '',
  });
  const [user] = useAuthState(auth);
  const userId = user ? user.uid : '';

  useEffect(() => {
    if (!userId) return;

    const fetchPersonalInfo = async () => {
      const userDocRef = doc(firestore, 'users', userId);
      const docSnapshot = await getDoc(userDocRef);

      if (docSnapshot.exists()) {
        setPersonalInfo(docSnapshot.data());
      }
    };

    fetchPersonalInfo();

    const unsubscribe = onSnapshot(doc(firestore, 'users', userId), (docSnapshot) => {
      if (docSnapshot.exists()) {
        setPersonalInfo(docSnapshot.data());
      }
    });

    return () => {
      unsubscribe();
    };
  }, [userId, firestore]);

  const handleChange = (e) => {
    setPersonalInfo({
      ...personalInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdate = async () => {
    const userDocRef = doc(firestore, 'users', userId);
    await setDoc(userDocRef, personalInfo);
    alert('Personal information updated successfully');
  };

  const inputFields = [
    'name', 'email', 'phone', 'address', 'linkdn', 'portfolio', 'github', 'other1', 'other2'
  ];

  return (
    <div className={styles.container}>
      <p className={styles.note}>This information will appear at the top of your resumes</p>
      {inputFields.map((field) => (
        <div className={styles.inputGroup} key={field}>
          <label className={styles.label} htmlFor={field}>
            {field.charAt(0).toUpperCase() + field.slice(1)}
          </label>
          <input
            className={styles.input}
            type="text"
            name={field}
            id={field}
            value={personalInfo[field]}
            onChange={handleChange}
          />
        </div>
      ))}
      <button className={styles.updateButton} onClick={handleUpdate}>
        Update
      </button>
    </div>
  );
};

export default PersonalInfo;