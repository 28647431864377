import React, { useState, useEffect } from 'react';
import "../css/resume.css"

function JobContainer(props) {

    useEffect(() => {
      }, [props]);

      return (
        <div className="jobWrapper">
            <div className="jobContainer1">
                <div className="jobSubContainer1">
                    <p className="dates">{props.start} - {props.end}</p>
                </div>
                <div className="jobSubContainer2">
                    <p><b>{props.title}</b>,  {props.company} - {props.location}</p>
                </div>
            </div>
            <div className="jobContainer2">
                <ul className="jobList">{props.experience}</ul>
            </div>
        </div>
    );
}
  
  export default JobContainer;

  // const styles = {
  //   wrapper: {"display": "flex", "flexDirection": "column", "width": "90%", "margin": "auto"},
  //   container1: {"display": "flex"},
  //   sub_container1: {"display": "flex", "justifyContent": "flex-start", "flexGrow": "2"},
  //   sub_container2: {"display": "flex", "justifyContent": "flex-start", "width": "550px"},
  //   container2: {"display": "flex", "justifyContent": "flex-end"},
  //   title: {},
  //   company: {},
  //   location: {},
  //   dates: {},
  //   list:{"width": "500px"}
  // }