import React from 'react';
import styles from './termsStyle.module.css';

const Cookie = () => {

  return (
    <div className={styles.contentWrapper}>
        <h2 className={styles.title}>Cookie Policy</h2>
        <p>Last updated: 4/28/2023</p>
        <p>This Cookie Policy explains how Resum-AI, operated by Joshua Reid, uses cookies and similar technologies when you visit our website ("Site"). By using our Site, you agree to the use of cookies and similar technologies in accordance with this policy.</p>
        
        <h3 className={styles.header}>What Are Cookies?</h3>
        <p>Cookies are small text files that are stored on your computer or mobile device when you visit a website. They are used to remember your preferences, help you navigate between pages efficiently, and improve your overall experience on the site. Cookies can also help ensure that the marketing you see online is more relevant to you and your interests.</p>

        <h3 className={styles.header}>How We Use Cookies</h3>
        <p>Our Site uses cookies for the following purposes:
            <ol>
                <li>Authentication: We use cookies to verify if a user is logged in. These cookies are essential for the Site's functionality, and without them, you may not be able to access certain parts of the Site or use some of our services.</li>
                <li>Local Storage: We use local storage to temporarily save text entered by users on our Site, ensuring that the text is not lost during the sign-up process. This enhances the user experience and helps prevent data loss.</li>
            </ol>
            Please note that we do not use cookies for advertising purposes.</p>
        
        <h3 className={styles.header}>Disabling Cookies</h3>
        <p>You can choose to disable cookies in your web browser settings. If you decide to disable cookies, please be aware that some features of our Site may not function properly, and your overall experience on the Site may be affected.</p>
        <p>To learn more about how to disable cookies in your specific web browser, consult the "Help" section of your browser or visit the following links for popular web browsers:
            <ul>
                <li><a href="https://support.google.com/chrome/answer/95647?hl=en">Google Chrome</a></li>
                <li><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">Mozilla Firefox</a></li>
                <li><a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">Apple Safari</a></li>
                <li><a href="https://support.microsoft.com/en-us/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy">Microsoft Edge</a></li>
                <li><a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">Internet Explorer</a></li>
            </ul>
        </p>

        <h3 className={styles.header}>Changes to This Cookie Policy</h3>
        <p>We may update this Cookie Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage you to periodically review this page for the latest information on our cookie practices.</p>

        <h3 className={styles.header}>Contact Us</h3>
        <p>If you have any questions about our use of cookies or this Cookie Policy, please contact us at help@resum-ai.com.</p>
  </div>
  );
};

export default Cookie;