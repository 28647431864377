import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { useAuth } from '../../authentication/useAuth';
import { getFirestore, getDoc, doc } from "firebase/firestore";
import { firebaseApp } from '../../firebase/firebaseConfig';
import JobContainer from './job_container';
import EduContainer from './edu_container';
import PersonalInfoContainer from './personal_info_container'
import "../css/resume.css"
import Popup from '../popup';

const Resume = forwardRef((props, ref) => {
    const [user] = useAuth();
    const [displaySkills, setDisplaySkills] = useState('');
    const [displayJobs, setDisplayJobs] = useState([]);
    const [displayEdu, setDisplayEdu] = useState('');
    const [hasPurchased, setHasPurchased] = useState(false);
    const [showPopup, setShowPopup] = useState(false)

    useEffect(() => {
        const hasUserPurchased = async () => {
          if (user) {
            const firestore = getFirestore(firebaseApp);
            const userRef = doc(firestore, "users", user.uid);
            const userDoc = await getDoc(userRef);
    
            if (userDoc.exists()) {
                setHasPurchased(userDoc.data().hasPurchased);
                setShowPopup(!userDoc.data().hasPurchased)
            }
          }
        };
    
        hasUserPurchased();
      }, []);
    
    const wrapperRef = useRef();
        useImperativeHandle(ref, () => ({
        getNode: () => wrapperRef.current
    }));

    const formatSkills= (data) => {
        const skills = data.Skills.map((skill) =>
            <li>{skill}</li>
        )
        setDisplaySkills(skills)
    }

    const formatJobs= (data) => {
        const jobs = data.Jobs
        let hold = []
        jobs.forEach(job => {
            let experience = job.Experience.map((exp) =>
                <li><span className='ExpSpanWrapper'><span className='expSpan'>{exp}</span></span></li>
            )
            hold.push(<JobContainer title={job.Title} company={job.Company} location={job.Location} start={job.Start_Date} end={job.End_Date} experience={experience}/>)
        });
        setDisplayJobs(hold)
    }

    const formatEdU= (data) => {
        const education = data.Education
        let hold = []
        education.forEach(edu => {
            hold.push(<EduContainer degree={edu.Degree} name={edu.Name} location={edu.Location}/>)
        });
        setDisplayEdu(hold)
    }

    useEffect(() => {
        if(props.data !== ''){
            const data = JSON.parse(props.data)
            formatSkills(data)
            formatJobs(data)
            formatEdU(data)
        }
      }, [props.data]);

    useEffect(() => {
        const preventCopy = (e) => {
            e.preventDefault();
            return false;
        };
    
        const wrapper = wrapperRef.current;
        
        if (!hasPurchased) {
            wrapper.addEventListener('contextmenu', preventCopy);
            wrapper.addEventListener('copy', preventCopy);
        } else {
            wrapper.removeEventListener('contextmenu', preventCopy);
            wrapper.removeEventListener('copy', preventCopy);
        }
    
        return () => {
            wrapper.removeEventListener('contextmenu', preventCopy);
            wrapper.removeEventListener('copy', preventCopy);
        };
    }, [hasPurchased]);

    const handlePopupClose = () => {
        setShowPopup(false)
    }

      return (
        <>
        {!hasPurchased && <>
            <Popup
                isOpen={showPopup}
                message={"Want full access to your resume? Buy tokens and download it immediately!"}
                buttonText={"Buy Tokens!"}
                linkPath={"/payment"}
                displayButton={true}
                handleClose={handlePopupClose}
            />
            </>}
        <div className={hasPurchased ? "resumeWrapper" : "resumeWrapper blurred preventCopy"} ref={wrapperRef}>
            <PersonalInfoContainer />
            <div className="skillsWrapper">
                <p className="skillsHeader">Skills</p>
                <hr className="hr"></hr>
                <ul className="skillsList">{displaySkills}</ul>
            </div>
            <div className="expWrapper">
                <p className="expHeader">Experience</p>
                <hr className="hr"></hr>
                {displayJobs}
            </div>
            <div className="eduWrapper">
                <p className="eduHeader">Education</p>
                <hr className="hr"></hr>
                <div className="eduSubWrapper">
                    {displayEdu}
                </div>
            </div>
        </div>
        </>
    );
})
  
  export default Resume;