import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth } from 'firebase/auth';
import { firebaseApp } from '../firebase/firebaseConfig';
import { getFunctions, httpsCallable } from "firebase/functions";
import './css/PaymentSettings.css';

const auth = getAuth(firebaseApp);
const functions = getFunctions();

const PaymentSettings = () => {
  const user = useAuthState(auth)
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [subscribed, setSubscribed] = useState("No Subscriptions")

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      try {
        const getCustomer = httpsCallable(functions, 'getCustomerId');
        if (user[0] && user[0].uid) {
          const customerId = await getCustomer({ userId: user[0].uid });
          const getSubStatus = httpsCallable(functions, 'getSubscriptionStatus');
          const subscriptionObject = await getSubStatus(customerId.data);

          let subStatus = false;
          let subscriptionData = subscriptionObject.data.data;
          if (subscriptionData.length > 0) { // has data in array, if array empty, then subStatus is false
            let status = subscriptionData[0].status;
            let isCanceled = subscriptionData[0].canceled_at;
            if (isCanceled == null) isCanceled = false;
            else isCanceled = true;
            if (status === "active") subStatus = "Active";
            if (isCanceled) subStatus = "Canceled"
          } else subStatus = "No Subscriptions"

          setSubscribed(subStatus);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchSubscriptionStatus();
  }, [user, setSubscribed]);

  // Placeholder function for canceling subscription
  const goToBillingPortal = async () => {
    try {
        const getCustomer = httpsCallable(functions, 'getCustomerId');
        if (user[0] && user[0].uid) {
          const customerId = await getCustomer({ userId: user[0].uid });
          const billingPortal = httpsCallable(functions, 'billingPortal');
          const billPortalURL = await billingPortal(customerId.data);

          window.location.replace(billPortalURL.data)
        }
      } catch (error) {
        console.error('Error:', error);
      }
  };

  return (
    <div className="container">
        <h1>Payment Settings</h1>

      <div className="description">
        <h2>Subscription Status: {subscribed}</h2>

        <button className="portalButton" onClick={goToBillingPortal}>
            View Purchase History & Manage Subscriptions
        </button>
        </div>


        <hr />
    </div>
  );
};

export default PaymentSettings;
