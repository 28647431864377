import { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { firebaseApp } from '../firebase/firebaseConfig';

export const useAuth = () => {
  const auth = getAuth(firebaseApp);
  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    console.log("...");
  }, [user, loading, error]);

  return [user, loading, error];
};