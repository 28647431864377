import React from 'react';
import styles from './termsStyle.module.css';

const Privacy = () => {

  return (
    <div className={styles.contentWrapper}>
        <h2 className={styles.title}>Privacy Policy</h2>
        <p>Last updated: 4/28/2023</p>
        <p>This Privacy Policy describes how Resum-AI, operated by Joshua Reid, collects, uses, maintains, and discloses information collected from users (each, a "User") of the Resum-AI website ("Site"). This privacy policy applies to the Site and all products and services offered by Resum-AI.</p>
        
        <h3 className={styles.header}>Personal Information</h3>
        <p>We may collect personal information from Users in a variety of ways, including when Users visit our Site, register on the Site, fill out a form, and in connection with other activities, services, features, or resources we make available on our Site. Users may be asked for their name, email address, phone number, and other personal information. We will collect personal information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personal information, except that it may prevent them from engaging in certain Site-related activities.</p>

        <h3 className={styles.header}>Non-Personal Information</h3>
        <p>We may collect non-personal information about Users whenever they interact with our Site. Non-personal information may include the browser name, the type of computer, and technical information about Users' means of connection to our Site, such as the operating system and the Internet service providers utilized and other similar information.</p>
        
        <h3 className={styles.header}>Web Browser Cookies</h3>
        <p>Our Site may use "local storage" to enhance User experience. The User's web browser stores information on their computer for record-keeping purposes and sometimes to track information about them. Users may choose to set their web browser to refuse local storage or to alert them when local storage is being sent. If they do so, note that some parts of the Site may not function properly.</p>

        <h3 className={styles.header}>How We Use Collected Information</h3>
        <p>Resum-AI collects and uses Users' personal information for the following purposes:
            <ul>
                <li>To improve customer service.</li>
                <li>To personalize user experience.</li>
                <li>To process transactions.</li>
                <li>To send periodic emails if the User decides to opt-in to our mailing list.</li>
            </ul></p>

        <h3 className={styles.header}>How We Protect Your Information</h3>
        <p>We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information, username, password, transaction information, and data stored on our Site.</p>
  
        <h3 className={styles.header}>Sharing Your Personal Information</h3>
        <p>We do not sell, trade, or rent Users' personal information to others. We may share generic aggregated demographic information not linked to any personal information regarding visitors and users with our business partners, trusted affiliates, and advertisers for the purposes outlined above. We may use third-party service providers to help us operate our business and the Site or administer activities on our behalf, such as sending out newsletters or surveys. We may share your information with these third parties for those limited purposes, provided that you have given us your permission.</p>

        <p>We reserve the right to share personal information with third parties in the future, but we will update this Privacy Policy and obtain consent from Users where required by law.</p>
        
        <h3 className={styles.header}>Third-Party Websites</h3>
        <p>Users may find advertising or other content on our Site that link to the sites and services of our partners, suppliers, advertisers, sponsors, licensors, and other third parties. We do not control the content or links that appear on these sites and are not responsible for the practices employed by websites linked to or from our Site. In addition, these sites or services, including their content and links, may be constantly changing. These sites and services may have their own privacy policies and customer service policies. Browsing and interaction on any other website, including websites that have a link to our site, is subject to that website's own terms and policies. We are not responsible for the privacy practices, content, or policies of these third-party websites or services, and you should review their respective policies before providing any personal information or engaging in any transactions with them.</p>
  </div>
  );
};

export default Privacy;