import React from "react";
import {
  getAuth,
  sendEmailVerification,
} from "firebase/auth";
import { firebaseApp } from "../firebase/firebaseConfig";
import styles from "./css/SignupForm.module.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from './useAuth';

const auth = getAuth(firebaseApp);

const VerifyEmail = () => {
  const [user] = useAuth();
  const navigate = useNavigate();

  const resendEmail = () => {
    sendEmailVerification(user)
    .then(() => {
      // Email sent
      console.log("Sending verification email");
    })
    .catch((error) => {
      // Handle Errors here.
      const errorMessage = error.message;
      console.error("Verification email error:", errorMessage);
    });
  }

  const handleVerificationCheck = () => {
    auth.currentUser.reload().then(() => {
      if (auth.currentUser.emailVerified) {
        // Email has been verified
        navigate("/", { state: { fromSignup: true } });
        window.location.reload()
      } else {
        // Email not verified yet
        console.log("Email not verified")
      }
    });
  };

  return (
    <div className={styles.container}>
        <div>
          <p className={styles.info}>
            Please verify your email to log into your account.
          </p>
          <button className={styles.button} onClick={handleVerificationCheck}>
            I have verified my email, check again
          </button><br/>
          <a href="" onClick={resendEmail}>
            Resend Verification Email
          </a>
        </div>
    </div>
  );
};

export default VerifyEmail;
