import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, onSnapshot } from 'firebase/firestore';
import { firebaseApp } from '../../firebase/firebaseConfig';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import "../css/resume.css"

const auth = getAuth(firebaseApp);
const firestore = getFirestore(firebaseApp);

const PersonalInfoContainer = () => {
  const [personalInfo, setPersonalInfo] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    linkdn: '',
    portfolio: '',
    github: '',
    other1: '',
    other2: '',
  });
  const [user] = useAuthState(auth);
  const userId = user ? user.uid : '';

  useEffect(() => {
    if (!userId) return;

    const fetchPersonalInfo = async () => {
      const userDocRef = doc(firestore, 'users', userId);
      const docSnapshot = await getDoc(userDocRef);

      if (docSnapshot.exists()) {
        setPersonalInfo(docSnapshot.data());
      }
    };

    fetchPersonalInfo();

    const unsubscribe = onSnapshot(doc(firestore, 'users', userId), (docSnapshot) => {
      if (docSnapshot.exists()) {
        setPersonalInfo(docSnapshot.data());
      }
    });

    return () => {
      unsubscribe();
    };
  }, [userId]);

  const formatPhoneNumber = (phoneNumber) => {
    const areaCode = phoneNumber.slice(0, 3);
    const prefix = phoneNumber.slice(3, 6);
    const lineNumber = phoneNumber.slice(6);
  
    return `(${areaCode}) ${prefix}-${lineNumber}`;
  };
  

  return (
    <div className="personalInfoWrapper">
        <div className="personalInfoName">{personalInfo.name}</div>
        <div className="personalInfoInfo">
            <ul className="personalInfoUl">
                {personalInfo.phone && <li>{formatPhoneNumber(personalInfo.phone)}</li>}
                {personalInfo.email && <li>{personalInfo.email}</li>}
                {personalInfo.address && <li>{personalInfo.address}</li>}
                {personalInfo.linkdn && <li>{personalInfo.linkdn}</li>}
                {personalInfo.portfolio && <li>{personalInfo.portfolio}</li>}
                {personalInfo.github && <li>{personalInfo.github}</li>}
                {personalInfo.other1 && <li>{personalInfo.other1}</li>}
                {personalInfo.other2 && <li>{personalInfo.other2}</li>}
            </ul>
        </div>
    </div>
);
};

export default PersonalInfoContainer;

// const styles = {
//     wrapper: {width: "95%", margin: "0 auto 50px auto", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-end"},
//     name: {fontSize: "6vh"},
//     info: {margin: "0 50px 0 0"},
//     ul: {listStyleType: "none", display: "flex", flexDirection: "column",alignItems:"flex-end", justifyContent: "space-evenly", height: "150px"}
// }