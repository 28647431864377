import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { firebaseApp } from '../firebase/firebaseConfig';

const firestore = getFirestore(firebaseApp);

const UnsubscribeFromEmail = () => {
    const location = useLocation(); // Using 'useLocation' to access the location object

    // Function to parse query string parameters
    const queryString = new URLSearchParams(location.search);
    const userId = queryString.get('ref'); // Getting 'userId' from '?ref=<userId>'

  useEffect(() => {
    if (!userId) return;

    const unsubscribeEmail = async () => {
      const userDocRef = doc(firestore, 'users', userId);
      const docSnapshot = await getDoc(userDocRef);

      if (docSnapshot.exists()) {
        const userData = docSnapshot.data();
        // Check if 'promo_emails' field is already false to avoid unnecessary updates
        if (userData.promo_emails !== false) {
          await setDoc(userDocRef, { promo_emails: false }, { merge: true });
          alert('You have been successfully unsubscribed from promotional emails.');
        } else {
          alert('You are already unsubscribed from promotional emails.');
        }
      } else {
        alert('User not found.');
      }
    };

    unsubscribeEmail();
  }, [userId]);

  return (
    <div>
      <h2>Unsubscribe From Emails</h2>
      <p>If you are seeing this page, your request is being processed.</p>
    </div>
  );
};

export default UnsubscribeFromEmail;
