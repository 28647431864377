import React from 'react';
import { Link } from 'react-router-dom';
import styles from './css/blog.module.css';

const BlogList = () => {

  return (
      <div className={styles.wrapper}>
        <Link to="/unlock-career-potential-ai-professional-growth" className={styles.link}>Unlock Your Career Potential: How AI Can Skyrocket Your Professional Growth</Link>
      </div>
  );
};

export default BlogList;
