import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth } from 'firebase/auth';
import { firebaseApp } from '../firebase/firebaseConfig';
import { Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { getFunctions, httpsCallable } from "firebase/functions";
import styles from './css/PaymentForm.module.css';
import Loading from '../components/Loading';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const auth = getAuth(firebaseApp);
const functions = getFunctions();


const prodPrice = {
    one: "price_1O0VcKGJdn4wTA00ePPNndpC,payment",
    three: "price_1O0VeXGJdn4wTA00GfsQeoDc,payment",
    five: "price_1O0VgPGJdn4wTA00xip3BA1i,payment",
    subscription: "price_1O2QmzGJdn4wTA00X0XJw0N7,subscription"
}

const testPrice = {
    one: "price_1O0VjwGJdn4wTA00kqlWulOt,payment",
    three: "price_1O0VkqGJdn4wTA00iyTP2wTY,payment",
    five: "price_1O0VlQGJdn4wTA00Gx1eqCfC,payment",
    subscription: "price_1O2b0tGJdn4wTA000JC1jKUY,subscription"
}


const PaymentFormContent = ({ onPayment }) => {
  const user = useAuthState(auth)
  const [selectedPlan, setSelectedPlan] = useState({ amount: '', paymentType: '' });
  const [subscribed, setSubscribed] = useState(false)

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      try {
        const getCustomer = httpsCallable(functions, 'getCustomerId');
        // Make sure user is defined and authenticated
        if (user[0] && user[0].uid) {
          const customerId = await getCustomer({ userId: user[0].uid });
          const getSubStatus = httpsCallable(functions, 'getSubscriptionStatus');
          const subscriptionObject = await getSubStatus(customerId.data);

          let subStatus = false;
          let subscriptionData = subscriptionObject.data.data;

          if (subscriptionData.length > 0) { // has data in array, if array empty, then subStatus is false
            let status = subscriptionData[0].status;
            let isCanceled = subscriptionData[0].canceled_at;
            if (isCanceled == null) isCanceled = false;
            else isCanceled = true;
            if (status === "active" && isCanceled == false) subStatus = true;
          } 

          setSubscribed(subStatus); // Update the state
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    // Call the async function
    fetchSubscriptionStatus();
  }, [user, setSubscribed]);

  const handlePayment = async (event) => {
    event.preventDefault();
    onPayment(selectedPlan);
  };

  const handleAmountChange = (event) => {
    const [amount, paymentType] = event.target.value.split(',');
    setSelectedPlan({ amount, paymentType, subscribed });
  };

  return (
    <div className={styles.paymentForm}>
      <form onSubmit={handlePayment}>
        <div className={styles.radioContainer}>
          <input type="radio" id="plan1" value={prodPrice.one} checked={selectedPlan.amount + ',' + selectedPlan.paymentType === prodPrice.one} onChange={handleAmountChange} />
          <label htmlFor="plan1" className={styles.radioLabel}>
            <span className={styles.planName}>1 Resume / Coverletter</span>
            <span className={styles.price}>$5.97</span>
            <span className={styles.savings}>$5.97 / Token</span>
          </label>
        </div>
        <div className={styles.radioContainer}>
          <input type="radio" id="plan2" value={prodPrice.three} checked={selectedPlan.amount + ',' + selectedPlan.paymentType === prodPrice.three} onChange={handleAmountChange} />
          <label htmlFor="plan2" className={styles.radioLabel}>
            <span className={styles.planName}>3 Resumes / Coverletters</span>
            <span className={styles.price}>$13.97</span>
            <span className={styles.savings}>$4.65 / Token</span>
          </label>
        </div>
        <div className={styles.radioContainer}>
          <input type="radio" id="plan3" value={prodPrice.five} checked={selectedPlan.amount + ',' + selectedPlan.paymentType === prodPrice.five} onChange={handleAmountChange} />
          <label htmlFor="plan3" className={styles.radioLabel}>
            <span className={styles.planName}>5 Resumes / Coverletters</span>
            <span className={styles.price}>$19.97</span>
            <span className={styles.savings}>$3.99 / Token</span>
          </label>
        </div>
        {subscribed &&(
          <div className={styles.radioContainer}>
          <label htmlFor="plan4" className={styles.subscribed}>
            <span className={styles.planName}>Subscribed</span>
          </label>
        </div>
        )}
        {!subscribed &&(
          <div className={styles.radioContainer}>
          <input type="radio" id="plan4" value={prodPrice.subscription} checked={selectedPlan.amount + ',' + selectedPlan.paymentType === prodPrice.subscription} onChange={handleAmountChange} />
          <label htmlFor="plan4" className={styles.radioLabel}>
            <span className={styles.planName}>10 Resumes / Coverletters<br/>Montly Subscription</span>
            <span className={styles.price}>$9.97</span>
            <span className={styles.savings}>$0.99 / Token</span>
          </label>
        </div>
        )}
        <button
          type="submit"
          className={`${styles.payNowButton} ${selectedPlan.amount === '' ? styles.disabled : styles.notDisabled}`}
          disabled={selectedPlan.amount === ''}
        >
          Complete Payment
        </button>
      </form>
    </div>
  );
};

const PaymentForm = () => {
    const user = useAuthState(auth)
    const [loading, setLoading] = useState(false);


    const customerId = async () => {
      try {
        const getCustomer = httpsCallable(functions, 'getCustomerId');
        const result = await getCustomer({ userId: user[0].uid });
        return result.data; // Return the data from the callable function
      } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error so it can be handled by the caller
      }
    };

    const handlePayment = async (selectedPlan) => {
        setLoading(true)
        const customer = await customerId();
        const { amount, paymentType, subscribed } = selectedPlan;
        if (paymentType == "subscription" && subscribed == true) window.location.replace("/payment")
        const processPayment = httpsCallable(functions, 'processPayment');
        processPayment({
                price: amount,
                paymentType: paymentType,
                success_url: window.location.origin + "/paymentConfirmation",
                cancel_url: window.location.origin + "/payment",
                customer: customer
                })
        .then(result => {
            window.location.replace(result.data)
        })
        .catch(error => {
            console.error('Error:', error);
            setLoading(false)
        });
      };

  return (
    <Elements stripe={stripePromise}>
    {loading && ( 
      <Loading message="Transfering you to Stripe to complete payment" />
    )}
      <PaymentFormContent onPayment={handlePayment} />
    </Elements>
  );
};

export default PaymentForm;