import React from 'react';
import { getAuth, signOut } from 'firebase/auth';

import { firebaseApp } from '../firebase/firebaseConfig';
import styles from './css/LogOut.module.css';

const auth = getAuth(firebaseApp);

const LogOut = () => {
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // User signed out successfully
        console.log('User signed out successfully');
      })
      .catch((error) => {
        // Error occurred while signing out
        console.error(error);
      });
  };

  return (
    <nav className={styles.nav}>
          <a className={styles.link} onClick={handleLogout}>
              Log Out
            </a>
          </nav>
  );
};

export default LogOut;