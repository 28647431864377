import React, { useState, useEffect, useRef } from 'react';
import { getFirestore, collection, onSnapshot } from 'firebase/firestore';
import { firebaseApp } from '../firebase/firebaseConfig';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import Resume from '../components/resume_sub_components/resume';
import CoverLetter from '../components/coverLetter'; // Import the CoverLetters component
import styles from './css/DisplayResumes.module.css';
import DownloadPdfButton from '../components/DownloadPdfButton';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const pdfStyles = {
  sectionHeader: {
    fontSize: 14,
    bold: true,
    margin: [0, 20, 0, 10],
  },
  list: {
    margin: [20, 0],
  },
  jobTitle: {
    bold: true,
    margin: [0, 10, 0, 0],
  },
  jobCompany: {
    margin: [0, 2, 0, 0],
  },
  jobLocation: {
    margin: [0, 2, 0, 0],
  },
  jobDate: {
    margin: [0, 2, 0, 10],
  },
  eduDegree: {
    bold: true,
    margin: [0, 10, 0, 0],
  },
  eduName: {
    margin: [0, 2, 0, 0],
  },
  eduLocation: {
    margin: [0, 2, 0, 0],
  },
};

const auth = getAuth(firebaseApp);
const firestore = getFirestore(firebaseApp);

const DisplayResumes = () => {
  const [resumes, setResumes] = useState([]);
  const [coverLetters, setCoverLetters] = useState([]); // New state for cover letters
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [clonedNode, setClonedNode] = useState(null);
  const [user] = useAuthState(auth);
  const userId = user ? user.uid : '';

  const resumeRef = useRef();
  const hiddenDivRef = useRef();

  useEffect(() => {
    if (!userId) return;
    
    const fetchDocuments = async () => {
      const resumesRef = collection(firestore, 'items', userId, 'resumes');
      const coverLettersRef = collection(firestore, 'items', userId, 'coverletters');

      const resumeUnsubscribe = onSnapshot(resumesRef, (querySnapshot) => {
        const fetchedResumes = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          type: 'resume',
          ...doc.data(),
        }));
        setResumes(fetchedResumes);
        if (fetchedResumes.length > 0 && !selectedDocument) setSelectedDocument(fetchedResumes[0]);
      });

      const coverLetterUnsubscribe = onSnapshot(coverLettersRef, (querySnapshot) => {
        const fetchedCoverLetters = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          type: 'coverletter',
          ...doc.data(),
        }));
        setCoverLetters(fetchedCoverLetters);
      });

      return () => {
        resumeUnsubscribe();
        coverLetterUnsubscribe();
      };
    };

    fetchDocuments();
  }, [userId, firestore, selectedDocument]);

  useEffect(() => {
    if (!resumeRef.current || !selectedDocument) return;

    const cloned = resumeRef.current.getNode().cloneNode(true);
    hiddenDivRef.current.appendChild(cloned);
    setClonedNode(cloned);
  }, [selectedDocument]);

  const documents = [
    { title: 'Resumes', items: resumes },
    { title: 'Cover Letters', items: coverLetters },
  ];

  const isMobile = window.innerWidth <= 768;

  return (
    <div className={styles.container}>
      <div ref={hiddenDivRef} style={{ position: 'absolute', left: '-9999px', width: '210mm' }}></div>
      <div className={styles.documentList}>
        {documents.map(({ title, items }) => (
          <React.Fragment key={title}>
            <div className={styles.dropdownHeader}>{title}</div>
            <div className={`${styles.itemsContainer} ${isMobile ? styles.mobileItemsContainer : styles.desktopItemsContainer}`}>
              {items.map((document) => (
                <div
                  key={document.id}
                  onClick={() => setSelectedDocument(document)}
                  className={`${styles.documentListItem} ${
                    selectedDocument && selectedDocument.id === document.id
                      ? styles.selectedDocument
                      : ''
                  }`}
                >
                  {document.id}
                  {document.type === 'resume' && 
                    <DownloadPdfButton
                    data={selectedDocument.type === 'resume' ? JSON.parse(selectedDocument.data) : selectedDocument.data}
                    fileName={document.id}
                    styles={pdfStyles}
                  />}
                </div>
              ))}
            </div>
          </React.Fragment>
        ))}
      </div>
      <div className={styles.document}>
        {selectedDocument && selectedDocument.type === 'resume' && (
          <>
          <Resume ref={resumeRef} data={selectedDocument.data} />
            
        </>
        )}
        {selectedDocument && selectedDocument.type === 'coverletter' && (
          <CoverLetter data={selectedDocument.data} />
        )}
      </div>
    </div>
  );
};

export default DisplayResumes;