export const pdfResumeContent = (data, styles, personalInfo) => {
  const { Skills, Jobs, Education } = data;

  const formatPersonalInfo = () => {
    const formattedInfo = [
      { text: personalInfo.phone || '', style: 'info' },
      { text: personalInfo.email || '', style: 'info' },
      { text: personalInfo.address || '', style: 'info' },
      { text: personalInfo.linkdn || '', style: 'info' },
      { text: personalInfo.portfolio || '', style: 'info' },
      { text: personalInfo.github || '', style: 'info' },
      { text: personalInfo.other1 || '', style: 'info' },
      { text: personalInfo.other2 || '', style: 'info' },
    ];
  
    const nonEmptyItems = formattedInfo.filter((item) => item.text !== '');
  
    if (nonEmptyItems.length === 0) {
      return [
        [
          {
            text: personalInfo.name || '',
            style: 'name',
          },
          {},
        ],
      ];
    }
  
    const personalInfoRows = nonEmptyItems.map((infoItem, index) => [
      index === nonEmptyItems.length - 2
        ? {
            text: personalInfo.name || '',
            style: 'name',
            rowSpan: 2,
          }
        : {},
      infoItem,
    ]);
  
    return personalInfoRows;
  };
  
  const skills = Skills.map((skill) => ({ text: skill, style: 'skills' }));
  const skillTableRows = [];
  for (let i = 0; i < skills.length; i += 3) {
    skillTableRows.push([
      skills[i] || { text: '', style: 'skills' },
      skills[i + 1] || { text: '', style: 'skills' },
      skills[i + 2] || { text: '', style: 'skills' },
    ]);
  }

  const jobs = Jobs.map((job) => {
    const experience = job.Experience.map((exp) => ['•', exp]);

    return [
      {
        columns: [
          { text: `${job.Start_Date} - ${job.End_Date}`, style: 'dates', width: 150 },
          [
            {
              columns: [
                { text: job.Title+",", style: 'title', width: 'auto' },
                { text: job.Company+" -", style: 'companyName', width: 'auto', margin: [10, 0, 0, 0] },
                { text: job.Location, style: 'companyLocation', width: 'auto', margin: [10, 0, 0, 0] },
              ],
              columnGap: -5,
            },
            {
              table: {
                headerRows: 0,
                widths: ['auto', '*'],
                body: experience,
              },
              layout: 'noBorders',
              margin: [20, 5, 0, 0],
            },
          ],
        ],
        columnGap: 10,
      },
      { text: '', margin: [0, 10, 0, 0] },
    ];
  });

  const education = Education.map((edu) => [
    {
      columns: [
        { text: edu.Degree, style: 'eduDegree', width: 'auto' },
        { text: edu.Name, style: 'eduName', width: 'auto', margin: [10, 0, 0, 0] },
        { text: edu.Location, style: 'eduLocation', width: 'auto', margin: [10, 0, 0, 0] },
      ],
      columnGap: 5,
    },
    { text: '', margin: [0, 10, 0, 0] },
  ]);

  const hr = {
    table: {
      widths: ['*'],
      body: [['']],
    },
    layout: {
      hLineWidth: function () {
        return 1;
      },
      vLineWidth: function () {
        return 0;
      },
      paddingTop: function () {
        return 0;
      },
      paddingBottom: function () {
        return 0;
      },
      paddingLeft: function () {
        return 0;
      },
      paddingRight: function () {
        return 0;
      },
    },
    margin: [0, 5, 0, 5],
  };

  const content = [
    {
      table: {
        widths: ['*', '*'],
        body: formatPersonalInfo(),
      },
      layout: 'noBorders',
      marginBottom: 10,
    },
    { text: 'Skills', style: 'sectionHeader' },
    hr,
    {
      table: {
        widths: ['*', '*', '*'],
        body: skillTableRows,
      },
      layout: 'noBorders',
      marginBottom: 0,
    },
    { text: 'Experience', style: 'sectionHeader' },
    hr,
    ...[].concat(...jobs),
    { text: 'Education', style: 'sectionHeader' },
    hr,
    ...[].concat(...education),
  ];

  return { content, styles };
};