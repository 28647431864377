import React, { useState, useEffect } from 'react';
import { getFirestore, collection, onSnapshot, doc, updateDoc, setDoc } from 'firebase/firestore';
import { firebaseApp } from '../firebase/firebaseConfig';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import styles from './css/DisplayDescriptions.module.css';


const auth = getAuth(firebaseApp);
const firestore = getFirestore(firebaseApp);

const DisplayDescriptions = () => {
  const [descriptions, setDescriptions] = useState([]);
  const [selectedDescription, setSelectedDescription] = useState(null);
  const [user] = useAuthState(auth);
  const userId = user ? user.uid : '';
  const [newDescriptionName, setNewDescriptionName] = useState('');

  useEffect(() => {
    if (!userId) return;

    const descriptionsRef = collection(firestore, 'items', userId, 'descriptions');
    const unsubscribe = onSnapshot(descriptionsRef, (querySnapshot) => {
      const fetchedDescriptions = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setDescriptions(fetchedDescriptions);
      if (fetchedDescriptions.length > 0) setSelectedDescription(fetchedDescriptions[0]);
    });

    return () => {
      unsubscribe();
    };
  }, [userId, firestore]);

  const handleSave = async () => {
    if (selectedDescription) {
      const descriptionRef = doc(firestore, 'items', userId, 'descriptions', selectedDescription.id);
      await updateDoc(descriptionRef, {
        data: selectedDescription.data,
      });
      alert('Description updated successfully');
    }
  };

  const handleAdd = async () => {
    if (!newDescriptionName.trim()) {
      alert('Please enter an experience name.');
      return;
    }
  
    const newDescription = {
      id: newDescriptionName,
      data: 'New description',
    };
    const descriptionRef = doc(firestore, 'items', userId, 'descriptions', newDescription.id);
    await setDoc(descriptionRef, newDescription);
    setNewDescriptionName('');
  };

  const isMobile = window.innerWidth <= 1200;

  return (
    <div className={styles.container}>
      {isMobile && (
      <>
      <div className={styles.inputContainer}>
        <input
          className={styles.nameInput}
          type="text"
          value={newDescriptionName}
          onChange={(e) => setNewDescriptionName(e.target.value)}
          placeholder="New experience name"
        />
        <button className={styles.addButton} onClick={handleAdd}>
          Add Experience
        </button>
      </div>
      <div className={styles.descriptionList}>
        {descriptions.length > 0 ? (
          descriptions.map((description) => (
            <div
              key={description.id}
              onClick={() => setSelectedDescription(description)}
              className={`${styles.descriptionListItem} ${
                selectedDescription && selectedDescription.id === description.id
                  ? styles.selectedDescription
                  : ''
              }`}
            >
              {description.id}
            </div>
          ))
        ) : (
          <p>Loading...</p>
        )}
      </div>
      </>
      )}
      {!isMobile && (
      <>
      <div className={styles.descriptionList}>
        <input
          className={styles.nameInput}
          type="text"
          value={newDescriptionName}
          onChange={(e) => setNewDescriptionName(e.target.value)}
          placeholder="New experience name"
        />
        <button className={styles.addButton} onClick={handleAdd}>
          Add Experience
        </button>
        {descriptions.length > 0 ? (
          descriptions.map((description) => (
            <div
              key={description.id}
              onClick={() => setSelectedDescription(description)}
              className={`${styles.descriptionListItem} ${
                selectedDescription && selectedDescription.id === description.id
                  ? styles.selectedDescription
                  : ''
              }`}
            >
              {description.id}
            </div>
          ))
        ) : (
          <p>Loading...</p>
        )}
      </div>
      </>
      )}
      <div className={styles.description}>
        {selectedDescription && (
          <textarea
            className={styles.editableBox}
            value={selectedDescription.data}
            onChange={(e) =>
              setSelectedDescription({ ...selectedDescription, data: e.target.value })
            }
          />
        )}
        <button className={styles.saveButton} onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  );
};

export default DisplayDescriptions;