import OpenAI from "openai";

    async function ask_question(prompt){
        const openai = new OpenAI({
                    organization: process.env.REACT_APP_OPEN_AI_ORG,
                    apiKey: process.env.REACT_APP_OPEN_AI_API_KEY,
                    dangerouslyAllowBrowser: true
                });
        const response = await openai.chat.completions.create({
            model: "gpt-4-1106-preview",
            messages: [
                { role: "system", content: "You are a resume and / or cover letter generating api service that ONLY RESPONDS in JSON format." },
                { role: "user", content: prompt },
              ],
              response_format: { type: "json_object" },
            })
            .catch((error) => {
                if (error.status === 429) {
                  throw new Error("Too many requests");
                } else {
                  throw error;
                }
              });
        return response.choices[0].message.content
    }

export default ask_question;
