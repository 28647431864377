import React from 'react';
import styles from './termsStyle.module.css';

const Refund = () => {

  return (
    <div className={styles.contentWrapper}>
        <h2 className={styles.title}>Refund/Cancellation Policy</h2>
        <p>Last updated: 12/30/2023</p>
        
        <h3 className={styles.header}>Subscription Cancellations</h3>
        <p>Users can cancel their subscription at any time. Upon cancellation, the subscription will not renew after the current billing cycle ends. Users will retain the tokens received during the subscription period and can continue to use any remaining tokens and access previously generated resumes and cover letters. The cancellation of a subscription does not delete the user's account or the content generated during the subscription period.</p>
        
        <h3 className={styles.header}>One-Time Purchases</h3>
        <p>All one-time purchases of tokens are final and non-refundable. Users are encouraged to review their purchase before confirmation carefully.</p>

        <h3 className={styles.header}>No Refunds for Subscriptions</h3>
        <p>We do not offer refunds or credits for partial subscription periods or unused tokens. Once a user cancels their subscription, they will not be charged in subsequent billing cycles, but no refunds will be provided for the current cycle.</p>

        <h3 className={styles.header}>Account Status After Cancellation</h3>
        <p>Your account will remain active and accessible even after the cancellation of a subscription. Users will retain access to any resources or content available prior to cancellation, including tokens received and resumes or cover letters generated.</p>

        <h3 className={styles.header}>Changes to This Refund/Cancellation Policy</h3>
        <p>We may update this Refund/Cancellation Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage you to periodically review this page for the latest information on our refund and cancellation practices.</p>

        <h3 className={styles.header}>Contact Us</h3>
        <p>If you have any questions about this Refund/Cancellation Policy, please contact us at help@resum-ai.com.</p>
  </div>
  );
};

export default Refund;