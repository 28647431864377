export const basic_resume_pre_prompt = `
Given the details of an individual's job experiences and education, generate a basic resume tailored for general use.

Requirements:

List 8 to 12 skills.
Provide work experiences in chronological order, with the most recent experience at the top.
For each job experience, include 3-4 bullet points detailing responsibilities and achievements using action verbs. If necessary, infer common responsibilities based on the job title and description.
Include the provided education details.

Your response for the resume should be in the following JSON format:

{
	"Skills": [
		"Skill 1",
		"Skill 2",
		"Ect"
		],
	"Jobs":[
		{
		"Title": "The title of job 1",
		"Company": "the name of the company",
		"Location": "the location I worked at",
		"Start_Date": "mm/yyyy",
		"End_Date": "mm/yyyy",
    "Experience": [
			"Bullet point 1",
			"Bullet point 2",
			"Ect"
			]
		}
		],
	"Education": [
			{
			"Name": "Springfield high school",
			"Degree": "high school diploma",
			"Location": "springfield, Va"
			}
    ]
}

Note: Use Unicode character U+0022 for quotation marks.
Note: Remember to act as an API service and only return JSON

Details:

Work Experience and Education:

`;

export const customized_resume_pre_prompt = `
Given the following details of an individual's job experiences, education, as well as the target job description and company details, generate a resume tailored for the target job.

The resume should have:

8 to 12 skills.
Chronologically ordered work experience with the most recent first.
For each job experience, provide 3-4 bullet points detailing responsibilities and achievements using action verbs.
Education details.

Your response for the resume should be in the following JSON format:

{
	"Skills": [
		"Skill 1",
		"Skill 2",
		"Ect"
		],
	"Jobs":[
		{
		"Title": "The title of job 1",
		"Company": "the name of the company",
		"Location": "the location I worked at",
		"Start_Date": "mm/yyyy",
		"End_Date": "mm/yyyy",
    "Experience": [
			"Bullet point 1",
			"Bullet point 2",
			"Ect"
			]
		}
		],
	"Education": [
			{
			"Name": "Springfield high school",
			"Degree": "high school diploma",
			"Location": "springfield, Va"
			}
    ]
}

Note: Use Unicode character U+0022 for quotation marks.
Note: Remember to act as an API service and only return JSON

Details:

Work Experience and Education:

`
export const customized_resume_post_prompt = `
Target Job and Company:

`

export const cover_letter_pre_prompt = `
Based on the provided details of an individual's job experiences, education, and the target job description and company, generate a cover letter that adheres to the following criteria:

Maintain a length between 250 and 400 words.
Ensure a concise and well-structured format, ideally fitting on one page.
Customize the content to align with the job's requirements and exhibit knowledge about the company.
Emphasize relevant skills and experiences with examples from the work or educational history provided.
Demonstrate genuine enthusiasm for the role and articulate the motivation behind pursuing the opportunity.
Uphold a professional tone, free of jargon, slang, and any grammatical errors.
Begin with a captivating introduction and conclude with a persuasive closing statement, reasserting qualifications and interest in the role.
Integrate keywords and phrases from the provided job description to highlight alignment with the position's requirements.

Your response for the resume should be in the following JSON format:

{
	"coverletter": ["Introduction", "Body 1", "Body 2", "Body 3", "Conclusion"]
}

Details:

Work Experience and Education:

`
export const cover_letter_post_prompt = `
Target Job and Company:

`