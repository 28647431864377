import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';

import { firebaseApp } from '../firebase/firebaseConfig';
import styles from './css/LoginForm.module.css';
import eyeOpen from '../assets/svg/eye-open.svg';
import eyeClosed from '../assets/svg/eye-closed.svg';

const auth = getAuth(firebaseApp);

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [forgotPasswordView, setForgotPasswordView] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/');
    } catch (error) {
      setLoginError('Wrong username or password');
    }
  };

  const handleForgotPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      alert('Password reset email sent. Please check your email.');
      setForgotPasswordView(false);
    } catch (error) {
      alert('Error sending password reset email: ' + error.message);
    }
  };

  const [user, loading, error] = useAuthState(auth);

  if (loading) {
    return (
      <div className={styles.container}>
        <p className={styles.message}>Loading...</p>
      </div>
    );
  }
  if (error) {
    return (
      <div className={styles.container}>
        <p className={styles.message}>Error: {error.message}</p>
      </div>
    );
  }
  if (user) {
    return (
      <div className={styles.container}>
        <p className={styles.message}>Welcome, {user.email}!</p>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={forgotPasswordView ? handleForgotPassword : handleSubmit}>
        {loginError && <p className={styles.error}>{loginError}</p>}
        <label className={styles.label}>
          {forgotPasswordView ? 'Enter email address of account' : 'Email:'}
          <input
            className={styles.input}
            type="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </label>
        {!forgotPasswordView && (
          <>
            <label className={styles.label}>
              Password:
              <input
                className={`${styles.input} ${styles.passwordInput}`}
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
              <img
                className={styles.togglePassword}
                src={showPassword ? eyeClosed : eyeOpen}
                alt="Toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
              />
            </label>
            <button className={styles.button} type="submit">
              Log In
            </button>
          </>
        )}
        {forgotPasswordView ? (
          <button 
            className={styles.button} 
            type="button"
            onClick={() => handleForgotPassword()}>
            Reset Password
          </button>
        ) : (
          <button
          className={styles.forgotPasswordButton}
          type="button"
          onClick={() => setForgotPasswordView(true)}
        >
          Forgot Password
        </button>
      )}
      {forgotPasswordView && (
        <button
          className={styles.forgotPasswordButton}
          type="button"
          onClick={() => setForgotPasswordView(false)}
        >
          Back to Login
        </button>
      )}
    </form>
  </div>
);
};

export default LoginForm;

