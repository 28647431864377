import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../authentication/useAuth";
import LogOut from "../authentication/LogOut";
import styles from "./css/header.module.css";
import { getFirestore, getDoc, doc } from "firebase/firestore";
import { firebaseApp } from "../firebase/firebaseConfig";

const Header = ({ tokenUpdated }) => {
  const [user] = useAuth();
  const [userTokens, setUserTokens] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [hamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);
  const dropdownRef = useRef(null);
  const hamburgerRef = useRef(null);
  const emailRef = useRef(null);

  useEffect(() => {
    const fetchUserTokens = async () => {
      if (user) {
        const firestore = getFirestore(firebaseApp);
        const tokenRef = doc(firestore, "tokens", user.uid);
        const tokenDoc = await getDoc(tokenRef);

        if (tokenDoc.exists()) {
          setUserTokens(tokenDoc.data().tokens);
        }
      }
    };

    fetchUserTokens();
  }, [user, tokenUpdated]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !emailRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }

      if (
        hamburgerMenuOpen &&
        !hamburgerRef.current.contains(event.target)
      ) {
        setHamburgerMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [hamburgerMenuOpen]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const isMobile = window.innerWidth <= 1200;

  return (
    <header className={styles.header}>
      <Link className={styles.logo} to="/">
        <span className={styles.resum}>Resum</span>-<span className={styles.ai}>AI</span>
      </Link>
      {(user && isMobile) && <Link className={styles.tokens} to="/payment">Tokens: {userTokens < 0 ? "Preview" : userTokens}</Link>}
      <button
        className={styles.hamburger}
        onClick={() => setHamburgerMenuOpen(!hamburgerMenuOpen)}
      >
        &#9776;
      </button>
      <nav ref={hamburgerRef} className={`${styles.nav} ${hamburgerMenuOpen ? styles.showNav : ""}`}>
        {!user && (
          <>
            <Link className={styles.link} to="/login">
              Login
            </Link>
            <Link className={styles.link} to="/signup">
              Sign Up
            </Link>
          </>
        )}
        {user && (
          <>
            <Link className={styles.link} to="/">
              New Resume!
            </Link>
            <Link className={styles.link} to="/view_resumes">
              My Resumes
            </Link>
            <Link className={styles.link} to="/view_experience">
              My Experience
            </Link>
            {isMobile && (
              <>
              <Link className={styles.link} to="/payment">
                Buy Tokens
              </Link>
              <Link className={styles.link} to="/personal_info">
                Personal Info
              </Link>
              <Link className={styles.link} to="/payment_settings">
                  Payment Settings
                </Link>
              <span className={styles.link}>{user.email.split("@")[0]}</span>
              <a className={styles.link} href="mailto:help@resum-ai.com">help@resum-ai.com</a>
              <LogOut />
              </>
            )}
            {!isMobile && (
              <>
              <Link className={styles.link} to="/payment">Tokens: {userTokens < 0 ? "Preview" : userTokens}</Link>
              <span
                className={styles.email}
                onClick={toggleDropdown}
                ref={emailRef}
              >
                &#9776;
              </span>
              </>
            )}
            {dropdownOpen && (
              <div className={styles.dropdown} ref={dropdownRef}>
                <Link className={styles.link} to="/personal_info">
                  Personal Info
                </Link>
                <Link className={styles.link} to="/payment_settings">
                  Payment Settings
                </Link>
                <LogOut />
              </div>
            )}
          </>
        )}
      </nav>
    </header>
  );
};

export default Header;
