import React from "react";
import styles from "./css/help.module.css";

const Help = ({ isOpen, handleClose }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className={styles.overlay}>
      <div className={styles.popup}>
        <button className={styles.closeButton} onClick={handleClose}>
          X
        </button>
        <div className={styles.popupContent}>
            <h2>How Does This Work?</h2>
            <p>
            Creating a personalized resume has never been easier! Just write a
            description about yourself as if you were having a conversation with
            someone, and let our AI do the rest. It will generate a pre-formatted
            resume, tailored to the job you're applying for, and even craft a
            customized cover letter.
            </p>

            <h2>What Should I Write About Myself?</h2>
            <p>
            Spend a few paragraphs detailing your background and experience.
            Include your education, work history with dates, and achievements or
            skills you've gained at each job. The more comprehensive your
            description, the better your resume will be. Give it a personal touch
            and let your unique qualities shine through!
            </p>

            <h2>How Can I Make the Most of This Tool?</h2>
            <p>
            In today's competitive job market, hiring managers receive hundreds of
            applications per week, particularly when posting on online job boards.
            As a result, they often rely on tools that scan resumes, screening for
            those that best match the skills outlined in the job description. To
            ensure your resume gets seen by a real person, you'll need to
            customize it for each application, which can be incredibly
            time-consuming and lead to missed opportunities.
            </p>
            <p>
            That's where Resum-AI comes in. Our powerful tool allows you to create
            a resume and cover letter specifically tailored for each job
            application. Once you have a detailed description of yourself, simply
            copy and paste the job description, and watch as your customized
            resume and cover letter are generated. Sit back, relax, and get ready
            for your interviews to start rolling in!
            </p>

            <h2>Example of a good description</h2>
            <p>
            I graduated from <i>High School Name</i> in 2015 with a high
            school diploma. <br></br><br></br>
            From 2015 to 2016, I worked at a Dunkin Donuts in
            <i> Location Name</i>. During my time there, I gained experience in
            customer service, conflict resolution, and working under pressure in a
            fast-paced environment.<br></br><br></br>
            In 2016, I joined the US Navy as an IT
            Specialist and served for five years. I was stationed in <i>Location Name</i>,
            and acquired skills in understanding electromagnetic waves,
            troubleshooting electrical systems, operating Linux OS, and creating
            important documentation for the entire command. <br></br><br></br>
            In 2021, I transitioned to the civilian workforce and joined <i>Company Name</i> in <i>Location Name</i>,
            as a Software Engineer for just under a year. At <i>Company Name</i>, I managed,
            maintained, and developed new features for both internal and external
            websites. My responsibilities included full-stack engineering using a
            LAMP stack and managing the entire IT department. <br></br><br></br>
            In 2022, I moved to <i>Company Name</i> as 
            a DevOps Engineer specializing in Site Reliability
            Engineering (SRE), working remotely. My primary tasks involved
            analyzing telemetry data to assess system success rates, determining
            the need for triage on specific systems, and creating automation using
            CI/CD pipelines to ensure smooth system operation or alert the team of
            potential failures.
            </p>
        </div>
        
      </div>
    </div>
  );
};

export default Help;
