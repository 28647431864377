import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Popup from '../components/popup';
import styles from "./css/Landing.module.css"

const Landing = () => {
  const [workExperience, setWorkExperience] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [popupOpen, setPopupOpen] = useState(false);
  const navigate = useNavigate();

  const handleClick = (event) => {
    event.preventDefault(); // Prevent form submission and page refresh
    localStorage.setItem('workExperience', workExperience);
    localStorage.setItem('jobDescription', jobDescription);
    setPopupOpen(true);
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
  };

  return (
    <div className={styles.wrapper}>
      <Popup
        isOpen={popupOpen}
        handleClose={handlePopupClose}
        message="Create a free account and get a preview of your resume!"
        sub_message="Don't worry, we'll save what you already wrote 😄"
        displayButton={true}
        buttonText="Sign Up!"
        linkPath="/signup"
      />
      <div className={styles.content}>
        <h1 className={styles.title}><span className={styles.resum}>Resum</span>-<span className={styles.ai}>AI</span></h1>
        <p className={styles.subtitle}>AI-powered resume and cover letter generator</p>
        <form className={styles.form}>
          <label className={styles.label}>Tell us about your work experience and education:</label>
          <textarea className={styles.textarea} value={workExperience} onChange={(e) => setWorkExperience(e.target.value)} />
        </form>
        <hr className={styles.hr}/>
        <form>
          <label className={styles.label}>Copy and paste a job description for a custom-tailored resume and cover letter:</label>
          <textarea className={styles.textarea} value={jobDescription} onChange={(e) => setJobDescription(e.target.value)} />
          <button className={styles.button} onClick={(e) => handleClick(e)}>Generate Custom Resume</button>
        </form>
        <div className={styles.additionalSection}>
          <h2>How Does This Work?</h2>
          <p>Creating a personalized resume has never been easier! Just write a description about yourself as if you were having a conversation with someone, and let our AI do the rest. It will generate a pre-formatted resume, tailored to the job you're applying for, and even craft a customized cover letter. <Link to="/signup">Sign up</Link> now for free and get started on your path to success!</p>

          <h2>What Should I Write About Myself?</h2>
          <p>Spend a few paragraphs detailing your background and experience. Include your education, work history with dates, and achievements or skills you've gained at each job. The more comprehensive your description, the better your resume will be. Give it a personal touch and let your unique qualities shine through!</p>

          <h2>How Can I Make the Most of This Tool?</h2>
          <p>In today's competitive job market, hiring managers receive hundreds of applications per week, particularly when posting on online job boards. As a result, they often rely on tools that scan resumes, screening for those that best match the skills outlined in the job description. To ensure your resume gets seen by a real person, you'll need to customize it for each application, which can be incredibly time-consuming and lead to missed opportunities.</p>
          <p>That's where Resum-AI comes in. Our powerful tool allows you to create a resume and cover letter specifically tailored for each job application. Once you have a detailed description of yourself, simply copy and paste the job description, and watch as your customized resume and cover letter are generated. Sit back, relax, and get ready for your interviews to start rolling in!</p>
          <Link className={styles.link} to="/signup">Sign Up Now!</Link>
        </div>
      </div>
    </div>
  );
};

export default Landing;