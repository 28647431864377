import React, { useEffect } from 'react';
import "../css/resume.css"

function EduContainer(props) {

    useEffect(() => {
      }, [props]);

    return (
      <>
        <h3>{props.degree}</h3><h6>{props.name}</h6><h6>{props.location}</h6>
      </>
    );
  }
  
  export default EduContainer;