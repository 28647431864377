import React, { useState, useEffect } from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { pdfResumeContent } from '../utils/pdfResumeContent';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { firebaseApp } from '../firebase/firebaseConfig';
import { ReactComponent as DownloadIcon } from "../assets/svg/download-button.svg"
import "./css/download_button.css"

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const DownloadPdfButton = ({ data, fileName }) => {
  const auth = getAuth(firebaseApp);
  const firestore = getFirestore(firebaseApp);
  const [user] = useAuthState(auth);
  const userId = user ? user.uid : '';
  const [hasPurchased, setHasPurchased] = useState(false);

  const styles = {
    name: {
      fontSize: 30,
      bold: true,
      lineHeight: 1.2,
      alignment: 'left',
    },
    info: {
      fontSize: 9,
      lineHeight: 0.8,
      alignment: 'right',
    },
    sectionHeader: {
      fontSize: 20,
      bold: true,
      marginBottom: 10,
    },
    skills: {
      fontSize: 12,
      marginBottom: 5,
    },
    experience: {
      fontSize: 12,
      marginLeft: 20,
      marginBottom: 5,
    },
    dates: {
      fontSize: 12,
      marginBottom: 5,
    },
    title: {
      fontSize: 12,
      bold: true,
      marginBottom: 5,
    },
    companyName: {
      fontSize: 12,
      marginBottom: 5,
    },
    companyLocation: {
      fontSize: 12,
      marginBottom: 5,
    },
    eduDegree: {
      fontSize: 12,
      bold: true,
      marginBottom: 5,
    },
    eduName: {
      fontSize: 12,
      marginBottom: 5,
    },
    eduLocation: {
      fontSize: 12,
      marginBottom: 5,
    },
  };

  useEffect(() => {
    const hasUserPurchased = async () => {
      if (user) {
        const firestore = getFirestore(firebaseApp);
        const userRef = doc(firestore, "users", user.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
            setHasPurchased(userDoc.data().hasPurchased);
        }
      }
    };

    hasUserPurchased();
  }, []);

  const handleDownload = async () => {
    if (!userId) return;
    if (hasPurchased == false) return;

    const userDocRef = doc(firestore, 'users', userId);
    const docSnapshot = await getDoc(userDocRef);

    const personalInfo = docSnapshot.exists() ? docSnapshot.data() : {};

    const { content } = pdfResumeContent(data, styles, personalInfo);
    const docDefinition = {
      content,
      styles,
      pageMargins: [40, 20, 40, 20], // [left, top, right, bottom]
    };

    pdfMake.createPdf(docDefinition).download(fileName);
  };

  return (
    <div className={hasPurchased ? "download-pdf-container" : "hasNotPurchased"}>
      <DownloadIcon
        onClick={handleDownload}
        width="24"
        height="24"
        className="download-pdf-icon"
      >
        <title>Download as PDF</title>
      </DownloadIcon>
    </div>
  );
};

export default DownloadPdfButton;