import React from 'react';
import { Link } from 'react-router-dom';
import styles from './termsStyle.module.css';

const TermsPolicies = () => {

  return (
      <div className={styles.wrapper}>
        <Link to="/privacy" className={styles.link}>Privacy Policy</Link>
        <Link to="/terms" className={styles.link}>Terms</Link>
        <Link to="/cookie" className={styles.link}>Cookie Policy</Link>
        <Link to="/disclaimer" className={styles.link}>Disclaimer</Link>
        <Link to="/copyright" className={styles.link}>Copyright Notice</Link>
        <Link to="/refund" className={styles.link}>Refund/Cancellation Policy</Link>
        <Link to="/aup" className={styles.link}>Acceptable Use Policy</Link>
      </div>
  );
};

export default TermsPolicies;
