import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { getFunctions } from "firebase/functions";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "resume-maker-efb48.firebaseapp.com",
    projectId: "resume-maker-efb48",
    storageBucket: "resume-maker-efb48.appspot.com",
    messagingSenderId: "704688335953",
    appId: "1:704688335953:web:821af18c8e0b285c805c70",
    measurementId: "G-JFK1QPKMJE"
  };

  const firebaseApp = initializeApp(firebaseConfig);
  const analytics = getAnalytics(firebaseApp);
  const perf = getPerformance(firebaseApp);
  const functions = getFunctions(firebaseApp);
  const auth = getAuth(firebaseApp);
  
  export { firebaseApp, analytics, functions, auth, perf};